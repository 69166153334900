<template>
  <div class="four">

    <div class="four-left">
      <h2>404</h2>
      <p>您的访问页面可能被删除或者不存在 <br/>
        请勿乱给本站的程序提交非法参数</p>
      <div class="four-left-button">
        <el-button @click="returnGo" >返回</el-button>
        <el-button @click="refresh" >刷新</el-button>
      </div>
    </div>

    <img class="four-right" src="../../assets/Four/four.png" alt="">

  </div>
</template>

<script>
export default {
  name: "Four",
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    returnGo() {
      this.$router.go(-1);
    },
    refresh() {
      this.$router.go(0);
    }
  }
}
</script>

<style lang="scss" scoped>
 @import "../../style/Four/Four";
</style>
